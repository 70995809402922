<template>
  <ReporteTransaccionesDetalladasPorUsuario
    titulo="Reportes - Ventas Detalladas por Usuario"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="venta"
  />
</template>

<script>
import ReporteTransaccionesDetalladasPorUsuario from "../../components/ReporteTransaccionesDetalladasPorUsuario.vue";
import ReporteVentasService from "../services";

export default {
  name: "ReporteVentasDetalladasPorUsuarioLayout",
  components: {
    ReporteTransaccionesDetalladasPorUsuario,
  },
  methods: {
    servicioGetReporte:
      ReporteVentasService.getReporteVentasDetalladasPorUsuario,
    servicioGetReportePdf:
      ReporteVentasService.getReporteVentasDetalladasPorUsuarioPdf,
  },
};
</script>